body,html {
  background:linear-gradient(105deg, lightgreen,white);
  font-family: sans-serif;
  }
  .head{
    color:white;
    font-weight:900;
    font-family:cursive;
    }
    #tree{
    color:green;
    }
  #header{
    color:white;
    font-size:50px;
    font-weight:900;
    font-weight: bold;
    }
   .logo{
    float: left;
    padding: 10px;
   
   }
  .logo:hover{
    text-decoration: none;
  }
  nav{
    display:block;
    width:100%;
    margin-bottom: 100px;
    }
  ul{
    list-style: none;
    display: flex;
    width:100%;
    flex-direction:row;
    padding: 2px;
}
  ul li{
    padding:10px;
    margin: auto;
   }
  ul li:hover{
   border-radius: 15px;
   background:lightgreen;
  }
  ul li a{
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: white;
  }
  ul li a:hover{
    text-decoration: none;
    font-weight: 700;
    color:darkgreen;
    text-transform:uppercase;
  }
  main{
    display:block;
    }
  #intro{
    min-height:765px;
    text-align:center;
    width:100%;
    font-weight:bold;        
    background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(https://images.unsplash.com/photo-1568832359672-e36cf5d74f54?ixlib=rb-1.2.1&w=1000&q=80);
    color:black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   }
  #intro{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
  }
  .picMap{
  display: flex;
  flex-direction: row;
  }
  #intCont{
    min-height:500px;
    width: 400px;
    background:white;
    margin-top:80px;
    margin-left:30px;
    padding-left:20px;
    padding-right:10px;
    font-size:19px;
    font-family:monospace;
    margin-bottom:10px;
    border-radius: 20px;
    box-shadow: 0 3px 4px;
    padding-top:50px;
  }
  img{
   height:400px;
   width:300px;
  }
  #nstl{
    padding-top:40px;
  }
  #secondarr{
    font-size:4.0em;
    margin-top:290px;
    padding-top:10px;
    padding-right: 9px;
    padding-left: 10px;
    color: white;
  }
  #secondarr:hover{
    color:darkgreen;
  }
  .container{
  width: 80%;
  margin: auto;
  overflow:hidden;
}
  #break{
  padding: 15px;
  color:black;
  background: white;
  margin-top: 70px;
  border-radius: 1px;
  box-shadow: 0 3px 4px;
}
#break h1{
  float: left;
  font-size: 28px;

}
#break form{
  float: right;
  margin-top: 15px;
}
#break textarea{
  padding: 4px;
  height: 25px;
  width: 250px;
}
  .button_1{
  height: 38px;
  background:linear-gradient(105deg, lightgreen,white);
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
  box-shadow: 0 1px 2px;
}
  .button_1 a{
    text-decoration:none;
     color:darkgreen;
  }
  .highlight{
    color:green;
    font-weight:bold;
  }
  #projects{
    height:565px;
    text-align:center;
    width:90%;
    background:white;
    margin-top:70px;
    padding-top:90px;
    margin-left:70px;
    border-radius: 9px;
    box-shadow: 0 3px 4px;
  }
  .title{
    padding: 80px;
    font-weight: bold;
  }
  #system{
    height:565px;
    text-align:center;
    width:90%;
    background:white;
    margin-top:70px;
    padding-top:90px;
    margin-left:70px;
    border-radius: 9px;
    box-shadow: 0 3px 4px;
  }
  #products{
    height:565px;
    text-align:center;
    width:90%;
    background:white;
    margin-top:70px;
    padding-top:90px;
    margin-left:70px;
    border-radius: 9px;
    box-shadow: 0 3px 4px;
  }
  #interv{
    height:565px;
    text-align:center;
    width:90%;
    background:white;
    margin-top:70px;
    padding-top:90px;
    margin-left:70px;
    border-radius: 9px;
    box-shadow: 0 3px 4px;
  }
  #about{
    height:565px;
    text-align:center;
    width:50%;
    background:white;
    margin-top:80px;
    padding-top:90px;
    margin-left:70px;
    padding:40px;
    border-radius: 9px;
    box-shadow: 0 3px 4px;
  }
  #contact{
    height:265px;
    text-align:center;
    width:30%;
    background:white;
    margin-top:100px;
    margin-left:500px;
    margin-bottom: 50px;
    border-radius: 9px;
    box-shadow: 0 3px 4px
  }
  footer{
    padding: 20px;
    margin-top: 20px;
    color: white;
    background-color:black;
    text-align:center;
  }
  /*media queries*/
  @media(max-width: 768px){
  nav{
  text-align: center;
  width: 100%;
  margin:auto;
  }
  ul{
  flex-direction:column;
  font-size:4px;
  margin: auto;
  }
  ul li{
  padding:5px;
  }
  main{
  margin: auto;
  }
  #intro{
  margin:auto;
  }
  #intCont{
  width:80%;
  margin-top: 90px;
  margin-left:20px;
  font-size:12px;
  height:400px;
  }
  img{
  width:200px;
  height: 380px;
  }
  #secondarr{
  font-size:18px;
  margin-top:280px;
  padding:10px;
  }
  #projects, #system,
  #products, #interv,
  #about, #contact{
  margin:15px;
  text-align: center;
  width: 93%;
  }
  .title{
  font-size: 20px;
  text-align: center;
  }
  #break h1, #break form{
  text-align: center;
  float:none;
  width:100%;
  }
	.button_1, #break textarea{
  display: block;
  width: 100%;
  }
  }